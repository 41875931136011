<template>
  <div class="video-cmp" v-show="!isHidden" :style="CSS" :class="CSSClasses">
      <video ref="videoPlayer" class="video-js"></video>
      <div class="playlist custom_scrollbar" v-if="listVideo.length">
      <template v-for="item in listVideo">
        <img
        v-if="item.poster"
        :src="item.poster" alt=''
        :key="item.id" loading="lazy"
        v-loading="loading && activeVideo === item.id"
        :class="{'active': activeVideo === item.id}"
         class="poster"
        @click="changeVideoSource(item)"
        >
        <div v-loading="loading && activeVideo === item.id" @click="changeVideoSource(item)" v-if="!item.poster" :key="item.id" style="background: #000; margin: 5px 0" class="poster"></div>
      </template>
      </div>
    <slot></slot>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import FilterBuilder, { EComponentTypes } from '@/components/InterfaceEditor/components/utils'
import { isValidJson } from '@/helpers/index'
const TYPEVIDEO = {
  'mp4': 'video/mp4',
  'WebM': 'video/WebM',
  'Ogg': 'video/Ogg'
}
export default {
  name: 'a-video',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    },
    $getModel: {
      default: () => {}
    },
    isEditor: {
      default: () => false
    }
  },
  mixins: [VisibleMixin, mixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    },
    alwaysActive: {
      type: Boolean,
      description: 'always_active'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },
    Video: {
      type: Object,
      editor: 'Video',
      default: () => {
        return {
          autoplay: false,
          controls: true,
          sources: []
        }
      }
    }
  },
  data () {
    return {
      player: null,
      loading: false,
      isRenderVideo: false,
      listVideo: [],
      activeVideo: 0
    }
  },
  computed: {
    getModelLocal () {
      if (!this.isEditor()) {
        return this.$getModel()
      }
      return {}
    },
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.calendar
      )

      const filters = builder.buildAsApiQl()

      return filters.length > 0
        ? {
          where: {
            and: [...filters]
          }
        }
        : null
    }
  },
  watch: {
    getModelLocal: {
      handler (val) {
        if (!this.isRenderVideo && this.Video.sourceVideo?.type === 'field') {
          this.initVideo()
        }
      },
      immediate: false
    }

  },
  methods: {
    changeVideoSource (videoSource) {
      let source = videoSource.sources[0]
      let poster = videoSource.poster
      this.loading = true

      this.player.on('loadstart', () => {
        this.player.poster(poster || '')
      })
      this.player.src(source)

      this.activeVideo = videoSource.id
    },
    async initVideo () {
      const optionsVideo = {
        autoplay: false,
        controls: this.Video.controls,
        preload: 'auto',
        language: 'RU',
        poster: '',
        sources: []
      }
      let sourceData = await this.prepareSource(this.Video.sourceVideo?.type)
      console.log('initVideo', sourceData)

      if (!Object.keys(sourceData).length || this.isEditor()) {
        console.log(`Источник данных в видео не определен`)
        return
      }
      let { type, src, poster } = sourceData
      this.isRenderVideo = true
      optionsVideo.sources.push({ src, type })
      optionsVideo.poster = poster || ''
      console.log('a-video', optionsVideo)
      this.$nextTick(() => {
        this.player = videojs(this.$refs.videoPlayer, optionsVideo)
        this.player.on('canplaythrough', () => {
          // Запускаем воспроизведение
          console.log('canplaythrough')
          this.loading = false
          if (this.Video.autoplay) {
            this.player.play()
          }
        })
      })
    },
    // Registry
    async getRegistry (entityId) {
      try {
        const response = await this.$http
          .post(`${this.$config.api}/registryservice/registry/${entityId}`, this.dataFilters, { hideNotification: true })

        return response.data.data
      } catch (error) {
        console.log(error)
        return []
      }
    },
    // Extended object
    async getExtendedObject (id) {
      const response = await this.$http
        .post(`${this.$config.api}/datawarehouseservice/extended_object/${id}`, this.dataFilters, { hideNotification: true })

      return response.data
    },
    // Query
    async getRequests (id) {
      const response = await this.$http
        .post(`${this.$config.api}/datawarehouseservice/query/${id}`, this.dataFilters, { hideNotification: true })

      return response.data
    },
    parseJSONFileField ({ data, poster = false }) {
      let parseFileField = JSON.parse(data)
      if (parseFileField.length > 1) {
        // playlist
        for (let index = 0; index < parseFileField.length; index++) {
          const element = parseFileField[index]
          if (!poster) {
            let src = `${this.$config.api}/files/${this.getFilePath(element)}`
            let type = TYPEVIDEO[element.extension]
            this.listVideo.push({ 'sources': [{ src, type }], id: index })
          }
          if (poster) {
            let posterForPlayList = `${this.$config.api}/files/${this.getFilePath(element)}`
            this.listVideo[index].poster = posterForPlayList
          }
        }
      }

      // главный экран
      const element = parseFileField[0]
      if (poster) {
        return `${this.$config.api}/files/${this.getFilePath(element)}`
      }
      return {
        src: `${this.$config.api}/files/${this.getFilePath(element)}`,
        type: TYPEVIDEO[element.extension]
      }
    },
    // ожидается строка вида /files/guid.mp4
    /**
     @returns {Object} { src, type }
     */
    parceStringToSource (str) {
      let source = {}
      try {
        const src = `${this.$config.api}${str}`
        const format = str.split('.')[1]
        const type = TYPEVIDEO[format]
        source = { src, type }
        return source
      } catch (error) {
        return source
      }
    },
    /**
      парсинг входящих данных
     @return {Object} { src, type, poster, id } | [{ src, type, poster, id }]
     */
    prepareData ({ dataRegistry, listVideos, isRegistrySource = true }) {
      let arrRes = []
      let res = {}

      for (let index = 0; index < dataRegistry.length; index++) {
        if (!listVideos && index > 0) break
        const element = dataRegistry[index]
        let sourceVideoAttr = null
        let sourcePosterAttr = null

        if (isRegistrySource) {
          sourceVideoAttr = element[`attr_${this.Video.sourceVideo.fieldId}_`]
          sourcePosterAttr = element[`attr_${this.Video.sourceVideo.fieldIdPoster}_`]
        }
        if (!isRegistrySource) {
          sourceVideoAttr = element[this.Video.sourceVideo.fieldId]
          sourcePosterAttr = element[this.Video.sourceVideo.fieldIdPoster]
        }

        if (!sourceVideoAttr) return res

        if (isValidJson(sourceVideoAttr)) {
          if (!listVideos) {
            res = this.parseJSONFileField({ data: sourceVideoAttr })
          }
        } else {
          res = this.parceStringToSource(sourceVideoAttr)
        }

        if (isValidJson(sourcePosterAttr)) {
          if (!listVideos) {
            res['poster'] = this.parseJSONFileField({ data: sourcePosterAttr, poster: true })
          }
        } else {
          if (sourcePosterAttr) {
            res['poster'] = `${this.$config.api}${sourcePosterAttr}`
          }
        }

        res['id'] = index

        if (listVideos) {
          arrRes.push(res)
        }
      }

      return listVideos ? arrRes : res
    },
    // Заполнить список видео
    fillListVideo (data) {
      if (this.listVideo.length) return
      let res = data.map(({ src, type, poster, id }) => {
        return { 'sources': [{ src, type }], poster, id }
      })
      this.listVideo = res
    },
    /**
      Заполнить список видео
     @returns void
     */
    fillListVideoByConstant (data) {
      let res = data.map(({ constantPoster, constantVideo, id }) => {
        let { src, type } = this.parceStringToSource(constantVideo)
        let poster = `${this.$config.api}${constantPoster}`
        return { 'sources': [{ src, type }], poster, id }
      })
      this.listVideo = res
    },
    /**
      источник константа
     @returns {Object} { src, type, poster }
     */
    parseConstantsAsArray (data) {
      let result = {}
      result = data.map(({ constantPoster, constantVideo }) => {
        let { src, type } = this.parceStringToSource(constantVideo)
        let poster = `${this.$config.api}${constantPoster}`
        return { src, type, poster }
      })

      return result[0]
    },
    /**
      подготовка источников
     @returns {Object} { src, type, poster }
     */
    async prepareSource (typeSourceVideo = null) {
      let res = {}
      if (!typeSourceVideo) {
        return res
      }
      if (typeSourceVideo === 'registry' && this.Video.sourceVideo.fieldId) {
        let dataRegistry = await this.getRegistry(this.Video.sourceVideo.registryId)
        if (!dataRegistry.length) {
          return res
        }
        res = this.prepareData({ dataRegistry, listVideos: false })

        if (dataRegistry.length > 1) {
          let sources = this.prepareData({ dataRegistry, listVideos: true })
          this.fillListVideo(sources)
        }
        return res
      }

      if (typeSourceVideo === 'query' && this.Video.sourceVideo.queryId) {
        let dataRegistry = await this.getRequests(this.Video.sourceVideo.queryId)
        if (!dataRegistry.length) {
          return res
        }
        res = this.prepareData({ dataRegistry, listVideos: false, isRegistrySource: false })
        if (dataRegistry.length > 1) {
          let sources = this.prepareData({ dataRegistry, listVideos: true, isRegistrySource: false })
          this.fillListVideo(sources)
        }
        return res
      }

      if (typeSourceVideo === 'extended_object' && this.Video.sourceVideo.extendedObjectId) {
        let dataRegistry = await this.getExtendedObject(this.Video.sourceVideo.extendedObjectId)
        if (!dataRegistry.length) {
          return res
        }
        res = this.prepareData({ dataRegistry, listVideos: false, isRegistrySource: false })
        if (dataRegistry.length > 1) {
          let sources = this.prepareData({ dataRegistry, listVideos: true, isRegistrySource: false })
          this.fillListVideo(sources)
        }
        return res
      }

      if (typeSourceVideo === 'constant' && this.Video.sourceVideo.constants.length) {
        res = this.parseConstantsAsArray(this.Video.sourceVideo.constants)
        if (this.Video.sourceVideo.constants.length > 1) {
          this.fillListVideoByConstant(this.Video.sourceVideo.constants)
        }
      }

      if (typeSourceVideo === 'field') {
        const str = this.getModelLocal[this.Video.sourceVideo.attribute]
        if (!str) {
          return res
        }
        if (isValidJson(str)) {
          console.log('Формат JSON еще не умеем парсить')
        } else {
          res = this.parceStringToSource(str)
        }
      }

      return res
    }
  },
  mounted () {
    this.initVideo()
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style scoped>
.video-cmp {
  height: 100%;
  display: flex;
  gap: 20px;
}
.video-cmp .video-js {
  height: 99%;
  flex: 5;
}
.video-cmp .vjs-poster {
  height: 99%;
}
.video-cmp .playlist {
  flex: 1;
  overflow: auto;

}
.video-cmp .poster {
  object-fit: cover;
  width: 100%;
  height: 100px;
  cursor: pointer;
}
.video-cmp .poster.active {
  background: skyblue;
  padding: 10px;
  border-radius: 5%;
  box-sizing: border-box;
}
</style>
